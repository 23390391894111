
.results {
  overflow-y: auto;
  border: 1px solid #d0d0d0;
  padding: 0;
  max-height: 300px;

  background: white;
  list-style: none;

  li {
    padding: 3px 5px 2px;
    cursor: pointer;

    &.active {
      background-color: lightskyblue;
    }
  }
}
