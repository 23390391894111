
.pdf-viewer {
  position: relative;
  width: 100%;
  height: 50vh;

  &__container {
    position: absolute;
    overflow: auto;
    width: 100%;
    height: 100%;
  }
}

.isFullscreen {
  .pdf-viewer {
    height: 100%;
  }
}
