
.project-index {
  &__sidebar {
    .item__active {
      .q-item__section {
        font-weight: bold;
      }
    }
  }

  &__map {
    padding: 0;
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .map {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
    }

    .q-page-sticky {
      margin-bottom: 60px !important;
      z-index: 1000;
    }
  }
}
