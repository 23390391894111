
.document-preview {
  position: relative;

  &__buttons {
    position: absolute;
    z-index: 1000;
    bottom: 20px;
    right: 10px;

    > * {
      margin-left: 10px
    }
  }

  &__container {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
    background-color: var(--q-dark);

    img {
      width: auto;
      height: 100%;
    }

    div {
      text-align: center;

      .q-icon {
        font-size: 4em;
      }
    }
  }

  &.document-preview__fullscreen {
    position: fixed !important;
    height: 100%;
    padding: 40px;
    top: 0;
    right: 0;
    botom: 0;
    left: 0;
    border: 1px solid;
    z-index: 12000;
    border-radius: 4px;
    background-color: white;

    .document-preview__container {
      overflow: visible;
      z-index: 10;
      position: relative;
      height: 100% !important;
      width: 100% !important;
    }

    .document-preview__buttons {
      bottom: 60px;
      right: 60px;
    }
  }

  &__backdrop {
    position: fixed !important;
    cursor: pointer;
    background-color: var(--q-dark);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.mobile {
  .document-preview {
    &.document-preview__fullscreen {
      padding: 10px;

      .document-preview__buttons {
        bottom: 30px;
        right: 20px;
      }
    }
  }
}
