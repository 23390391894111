
.q-chip.status-chip--small {
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;

  .q-chip__content {
    display: none;
  }

  .q-chip__icon {
    margin: 0;
  }
}

.q-chip.status-chip {
  .q-chip__content {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.q-chip--outline.status-chip.text-warning {
  color: black !important;
  background-color: color-mix(in srgb, var(--q-warning) 15%, transparent) !important;
  border-color: var(--q-warning);
}
