
.scalable-element {
  width: 100%;
  height: 100%;

  &__controls {
    position: absolute;
    z-index: 1000;
    z-index: 5;
    bottom: 0;
    right: 40px;

    .q-btn {
      margin-left: 10px;

      .q-icon {
        font-size: 1.715em;
      }
    }
  }

  &.isFullscreen {
    .scalable-element {
      &__controls {
        bottom: 15px;
        right: 25px;
      }
    }
  }
}
