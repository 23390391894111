
input[type="file"] {
  position: absolute;

  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;

  -ms-filter: 'alpha(opacity=0)';
  font-size: 200px !important;
  direction: ltr;
  cursor: pointer;
}
